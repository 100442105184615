export function useReferrals() {
  const config = useRuntimeConfig()
  const baseUrl = `${config.public.baseUrl}/register/professional`

  function getVacancyReferralUrl(vacancy: any) {
    return `${baseUrl}?referral=public-vacancy-${vacancy.id}`
  }

  function getProfessionalReferralUrl(professional: any) {
    return `${baseUrl}?referral=public-professional-${professional.id}`
  }

  function getCompanyReferralUrl(company: any) {
    return `${baseUrl}?referral=public-company-${company.id}`
  }

  return {
    getVacancyReferralUrl,
    getProfessionalReferralUrl,
    getCompanyReferralUrl,
  }
}
